<template>
  <div class="qrcode-image-wrapper">
    <img
      v-if="image"
      :src="image.replace('data:image/png;', 'data:image/png;charset=utf-8;')"
      alt=""
    />
  </div>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";

export default {
  name: "QRImage",
  data() {
    return {
      image: null
    };
  },
  created() {
    this.getQRCodeImage();
  },
  computed: {
    qrCode() {
      const { code } = this.$route.params;
      return code;
    }
  },
  methods: {
    getQRCodeImage() {
      httpServiceAuth
        .get(`${apiEndpoints.company.qrCode}/${this.qrCode}`)
        .then(response => {
          if (response) {
            this.image = response.data.image;
          } else {
            this.image = null;
          }
        })
        .catch(() => {
          this.$router.push({ name: "r_login" });
        });
    }
  }
};
</script>
